<template>
  <v-container class="py-1">
    <div class="d-flex text-left pl-2 mb-1">
      <h2 class="text-h4 mb-0">{{ title }}</h2>
    </div>

    <v-expansion-panels
      v-model="panel"
      multiple
      class="mt-1 mb-2"
    >
      <v-expansion-panel
        v-for="(item, i) in faq"
        :key="i"
      >
        <v-expansion-panel-header class="text-h6 font-weight-bold blue--text text--darken-4">
          {{ item.question }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class=".text-md-body-1 secondary--text">
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    faq: {
      type: Array,
      default: () => []
    }
  }
}
</script>