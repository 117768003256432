<template>
  <v-container fluid ma-0 pa-0>
    <Banner />
    <Feature />
    <Faq 
      v-for="(value, key) in formattedFaqs"
      :key="key"
      :title="`${key} (${value.code})`"
      :faq="value.faqs"
    />
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Banner from '@/components/default/pages/Banner.vue'
import Feature from '@/components/default/pages/Feature.vue'
import Faq from '@/components/default/pages/faq/Faq.vue'

export default {
  components: {
    Banner,
    Faq,
    Feature
  },
   computed: {
    ...mapState({
      faqs: (state) => state.microsite.faqs
    }),
    formattedFaqs() {
      const formattedFaqs = {}

      this.faqs.forEach((faq) => {
        if (!formattedFaqs[faq.SuperCategory]) {
          formattedFaqs[faq.SuperCategory] = { code: faq.SuperCategoryCode, faqs: [] }
        }
        formattedFaqs[faq.SuperCategory].faqs.push({
          question: faq.Question,
          answer: faq.Answer
        })
      })

      return formattedFaqs
    }
  },
  mounted() {
    this.getFaqs()
  },
  methods: {
    ...mapActions({
      getFaqs: 'microsite/getFaqs'
    })
  }
}
</script>
